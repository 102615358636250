.pswp__dynamic-caption {
  color: #fff;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity .12s linear !important;
}

.pswp-caption-content {
  display: none;
}

.pswp__dynamic-caption a {
  color: #fff;
}

.pswp__dynamic-caption--faded {
  opacity: 0 !important;
}

.pswp__dynamic-caption--aside {
  width: auto;
  max-width: 300px;
  margin-top: 70px;
  padding: 20px 15px 20px 20px;
}

.pswp__dynamic-caption--below {
  width: auto;
  max-width: 700px;
  padding: 15px 0 0;
}

.pswp__dynamic-caption--on-hor-edge {
  padding-left: 15px;
  padding-right: 15px;
}

.pswp__dynamic-caption--mobile {
  background: #00000080;
  width: 100%;
  padding: 10px 15px;
  bottom: 0;
  right: 0;
  top: auto !important;
  left: 0 !important;
}
/*# sourceMappingURL=index.4d07f507.css.map */
